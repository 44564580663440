<template>
  <div v-if="formatReportDataStatistics" class="box box--white">
    <div class="statistics">
      <statistic
        v-for="(data, index) in formatReportDataStatistics"
        :key="index"
        :title="data.label"
        :value="data.value"
        :is-rating="false"
        :value-class="data.valueClass"
      />
    </div>

    <div class="divider divider--expand-16 mt-24 mb-24"></div>

    <div class="d-flex">
      <selection-box v-model="chartType" :options="chartTypeOptions" />

      <div class="d-flex">
        <date-range-picker
          v-model="params.dateContext"
          opens="left"
          format="yyyy-mm-dd hh:mm"
          @toggle="isDateRangeOpen = !isDateRangeOpen"
          :class="['mr-16 bg-gray', { active: isDateRangeOpen }]"
          :append-to-body="true"
          :date-format="dateFormat"
        />

        <selection-box
          v-model="params.groupBy"
          :options="getGroupByOptions()"
          :is-align-right="true"
          :enabled-values="getEnableGroupByValues()"
        />
      </div>
    </div>

    <bar-chart v-if="chartType === 1" class="mt-24" :chart-data="formatReportDataChart.dataBarChart" />

    <line-chart v-if="chartType === 0" class="mt-24" :chart-data="formatReportDataChart.dataLineChart" />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import unixTime from '@/mixins/unix-time'
import dateFormat from '@/mixins/date-range-format'
import groupBy from '@/mixins/group-by'
import toggleLoading from '@/mixins/toggle-loading'
import { CHART_TYPE_OPTIONS } from '@/utils/data-sets'
const BarChart = () => import('@/components/BarChart')
const LineChart = () => import('@/components/LineChart')
const Statistic = () => import('@/components/Statistic')
const SelectionBox = () => import('@/components/SelectionBox')

export default {
  data() {
    return {
      isDateRangeOpen: false,
      chartTypeOptions: CHART_TYPE_OPTIONS,
      chartType: 1,
      params: {
        dateContext: {
          startDate: new Date(),
          endDate: new Date()
        },
        groupBy: 1
      }
    }
  },

  mixins: [dateFormat, unixTime, groupBy, toggleLoading],

  components: {
    DateRangePicker,
    BarChart,
    LineChart,
    Statistic,
    SelectionBox
  },

  computed: {
    ...mapGetters('report', ['formatReportDataStatistics', 'formatReportDataChart'])
  },

  methods: {
    ...mapActions('report', ['getReportRegisterChart']),

    getParamsRequest(params = this.params) {
      return {
        type: params.groupBy,
        ...this.getDateRangeByUnixTime(params.dateContext)
      }
    }
  },

  created() {
    this.getReportRegisterChart(this.getParamsRequest()).finally(() => {
      this.toggleLoading()
    })
  },

  watch: {
    params: {
      handler(newValue) {
        this.toggleLoading()
        setTimeout(() => {
          this.getReportRegisterChart(this.getParamsRequest(newValue)).finally(() => {
            this.toggleLoading()
          })
        }, 200)
      },
      deep: true
    }
  }
}
</script>
