export default {
  methods: {
    getGroupByOptions() {
      return this.$formatGroupByOptions(this.params.dateContext).options
    },

    getEnableGroupByValues() {
      return this.$formatGroupByOptions(this.params.dateContext).enabledValues
    }
  }
}
